import { ref, watch, computed, reactive, toRefs } from "@vue/composition-api";
import api from "@/libs/api.js";
// Notification
import { useToast } from "vue-toastification/composition";
import i18n from "@/libs/i18n.js"; // i18n 인스턴스 가져오기

export default function useUsersList() {
  const toast = useToast();

  const maintable = ref(null);

  // const tableColumns = [
  //   { key: "id", label: '번호' },
  //   { key: "userId", label: '아이디' },
  //   { key: "userName", label: '성명' },
  //   { key: "extension", label: '내선 번호' },
  //   { key: "telNum", label: '연락처' },
  //   { key: "branchCode", label: '소속' },
  //   { key: "regDateStr", label: '등록일' },
  //   { key: "work", label: '작업' },
  //   ];

  const rowNum = ref(10);
  const totalRows = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 50, 100];
  const filter = ref("");
  const searchDate = ref(null);
  const searchString = ref(null);
  const searchPosition = ref(null);
  // const searchEndDate = ref(null);
  // const searchStartDate = ref(null);
  const searchDateType = ref("all");
  const tunnelCodeSearch = ref([]); // 검색에 사용되는 터널명
  const tunnelCodeStatus = ref("ALL"); // 터널코드값 변수
  const tunnelCodePopup = ref([]); // popup 에서 사용되는 터널명
  const tunnelInfoList = ref([]); // popup 에서 사용되는 터널정보
  const searchInputType = ref("scheduleName");
  const searchBound = ref("all");
  const dataMeta = computed(() => {
    const localItemsCount = maintable.value
      ? maintable.value.localItems.length
      : 0;
    return {
      from: rowNum.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: rowNum.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    };
  });

  const refetchData = async () => {
    await getSelectBoxItems();
    await getTunnelInfoList();
    maintable.value.refresh();
    // this.filter = "";
  };

  watch([currentPage, rowNum], () => {
    refetchData();
  });

  const getSelectBoxItems = async () => {
    try {
      let selectBoxItems = [{ text: i18n.t("label.L0021"), data: "ALL" }]; // 검색에 사용되는 셀렉트 박스값 넣을 변수 초기화

      // 셀렉트 박스 값 백엔드 호출
      const response = await api.getSelectBoxItems();
      // 셀렉트 박스에 값 세팅
      const { data } = response.data;
      for (const item of data.selectBoxItems) {
        const selectBoxItem = {
          // groupId별 codeId 값
          text: item.location,
          data: item.tunnelcode,
        };
        selectBoxItems.push(selectBoxItem);
      }

      tunnelCodeSearch.value = selectBoxItems;
      tunnelCodePopup.value = selectBoxItems.filter(
        // popup 에서 사용되는 셀렉트 박스값
        (item) => item.text != i18n.t("label.L0021")
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getList = async () => {
    try {
      // var searchType=searchType.value
      // console.log("🚀 ~ getList ~ searchInputType.value:", Number(searchString.value) / 1000)

      const response = await api.getScheduleList({
        bound: searchBound.value,
        page: currentPage.value,
        pageSize: rowNum.value,
        // startPosition:
        //   searchInputType.value == "startPosition" ? filter.value : "",
        // endPosition: searchInputType.value == "endPosition" ? filter.value : "",
        tunnelCode: tunnelCodeStatus.value,
        searchInputType: searchInputType.value,
        // searchString: searchString.value,
        searchString:
          searchInputType.value == "position"
            ? searchPosition.value
            : searchString.value,
        searchDate: searchDate.value,
        searchDateType: searchDateType.value,
        // searchStartDate: searchStartDate.value,
        // searchEndDate: searchEndDate.value,
        // startDate: searchType.value == "startDate" ? filter.value : "",
        // endDate: searchType.value == "endDate" ? filter.value : "",
      });
      const { data } = response.data;
      const { endRecordNum, page, pageCount, pageSize, startRecordNum, total } =
        data.paging;
      // currentPage.value= page
      totalRows.value = total;
      var scheduleList = data.scheduleList;
      for (let schedule of scheduleList) {
        let startDate = new Date(schedule.startDate);
        let endDate = new Date(schedule.endDate);
        schedule.startDay = startDate.getDay();
        schedule.endDay = endDate.getDay();
        schedule.startPosition = schedule.startPosition.trim();
        schedule.endPosition = schedule.endPosition.trim();
      }
      return scheduleList;
    } catch (err) {
      console.log(err);
    }
  };
  const deleteFunction = async (data) => {
    const response = await api.removeSchedule(data);
    return response;
  };
  const createFunction = async (data) => {
    data.startPosition = (data.startPosition / 1000).toFixed(2);
    data.endPosition = (data.endPosition / 1000).toFixed(2);
    await api.addSchedule(data);
  };
  const updateFunction = async (data) => {
    data.startPosition = (data.startPosition / 1000).toFixed(2);
    data.endPosition = (data.endPosition / 1000).toFixed(2);
    await api.modifySchedule(data.no, data);
  };
  const getTunnelInfoList = async () => {
    const response = await api.getTunnelInfoList();
    const { data } = response.data;
    var list = data.tunnelInfoList;
    for (let tunnelInfo of list) {
      tunnelInfo.startPosition = tunnelInfo.startPosition / 100;
      tunnelInfo.endPosition = tunnelInfo.endPosition / 100;
    }

    tunnelInfoList.value = list;
  };

  return {
    createFunction,
    deleteFunction,
    searchDateType,
    refetchData,
    getList,
    // tableColumns,
    rowNum,
    totalRows,
    currentPage,
    dataMeta,
    perPageOptions,
    filter,
    // searchEndDate,
    // searchStartDate,
    maintable,
    updateFunction,
    searchBound,
    searchDate,
    searchString,
    searchPosition,
    searchInputType,
    tunnelCodeSearch,
    tunnelCodeStatus,
    tunnelCodePopup,
    tunnelInfoList,
  };
}
