<template>
  <div>
    <b-modal
      ref="data"
      cancel-variant="outline-secondary"
      :ok-title="$t('button.B0001')"
      :cancel-title="$t('button.B0005')"
      centered
      :title="propTitle"
      @ok="onBtnOk"
      @cancel="onBtnCancel"
      size="lg"
      hideHeaderClose
      no-close-on-backdrop
      no-close-on-esc
      body-class="bodyCustomSchedule"
      footer-class="footerCustomSchedule"
    >
      <validation-observer ref="simpleRules">
        <b-form
          style="
            margin-top: 10px;
            margin-bottom: 10px;
            margin-right: 10px;
            margin-left: 10px;
          "
        >
          <fieldset class="sectionFieldset form-group">
            <!-- <legend>{{ $t("label.L0206") }}</legend> -->
            <b-row style="margin-top: 10px">
              <b-col md="2">
                <label for="">{{ $t("label.L0017") }}</label>
              </b-col>
              <b-col md="4">
                <v-select
                  v-model="scheduleInfo.tunnelCode"
                  :clearable="false"
                  label="text"
                  :reduce="(d) => d.data"
                  :options="tunnelCodePopup"
                />
              </b-col>
            </b-row>
            <b-row style="margin-top: 10px" align-v="center" >
              <b-col md="2">
                <label
                  >{{ $t("label.L0087")
                  }}<span class="requiredStar">*</span></label
                >
              </b-col>
              <b-col md="4" style="margin-top: 15px;" >
                <b-form-group>
                  <b-button-group size="lm">
                    <b-button
                      @click="scheduleInfo.bound = 0"
                      :pressed="scheduleInfo.bound == 0 ? true : false"
                      variant="outline-primary"
                      >{{ $t("label.L0020") }}</b-button
                    >
                    <b-button
                      @click="scheduleInfo.bound = 1"
                      :pressed="scheduleInfo.bound == 1 ? true : false"
                      variant="outline-primary"
                    >
                      {{ $t("label.L0019") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <label
                  >{{ $t("label.L0065")
                  }}<span class="requiredStar">*</span></label
                >
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    rules="required"
                    name="scheduleName"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      class="text-left"
                      autocomplete="off"
                      v-model="scheduleInfo.scheduleName"
                      type="text"
                      maxLength="40"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row style="margin-top: 10px">
              <b-col md="2">
                <label
                  >{{ $t("label.L0073")
                  }}<span class="requiredStar">*</span></label
                >
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    rules="required|decimalPoint"
                    name="startP"
                    v-slot="{ errors }"
                  >
                    <!-- <validation-provider
                    rules="required|check_position"
                    name="startP"
                    v-slot="{ errors }"
                  > -->
                    <b-input-group class="input-group-merge merge-custom-hja">
                      <b-form-input
                        class="text-right"
                        autocomplete="off"
                        v-model="scheduleInfo.startPosition"
                        type="text"
                        maxLength="5"
                      />
                      <b-input-group-append is-text>
                        <span style="color: #999999">m</span>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger" v-if="errors.length > 0">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <label
                  >{{ $t("label.L0074")
                  }}<span class="requiredStar">*</span></label
                >
              </b-col>

              <b-col md="4">
                <b-form-group style="margin-right: -3px">
                  <validation-provider
                    rules="required|decimalPoint"
                    name="endP"
                    v-slot="{ errors }"
                  >
                    <!-- <validation-provider
                    rules="required|check_position"
                    name="endP"
                    v-slot="{ errors }"
                  > -->
                    <b-input-group class="input-group-merge merge-custom-hja">
                      <b-form-input
                        class="text-right"
                        autocomplete="off"
                        v-model.trim="scheduleInfo.endPosition"
                        type="text"
                        maxLength="5"
                      />
                      <b-input-group-append is-text>
                        <span style="color: #999999">m</span>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger" v-if="errors.length > 0">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- <b-row style="height: 50.45px"> </b-row> -->

            <b-row style="margin-top: 10px">
              <b-col md="2">
                <label for="" style="width: 100px"
                  >{{ $t("label.L0169") }}
                  <span class="requiredStar">*</span></label
                >
              </b-col>
              <b-col md="4">
                <validation-provider rules="required" v-slot="{ errors }">
                  <b-input-group :class="errors[0] != null ? 'is-invalid' : ''">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr
                      v-model="mergeDate"
                      ref="flatP"
                      class="form-control"
                      placeholder="날짜를 선택하세요."
                      :config="flatPickrConfig"
                      style="padding-right: 7px"
                      :class="errors[0] != null ? 'is-invalid' : ''"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>
              <b-col md="2">
                <label for="" style="width: 100px"
                  >{{ $t("label.L0170")
                  }}<span class="requiredStar">*</span></label
                >
              </b-col>
              <b-col md="4">
                <b-row>
                  <b-col md="4" style="padding: 0px 7px 0px 0px !important">
                    <b-form-group style="width: 80px">
                      <validation-provider
                        :rules="scheduleInfo.type == 'C' ? '' : 'required'"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            <feather-icon icon="CalendarIcon" />
                          </b-input-group-prepend>
                          <flat-pickr
                            v-model="scheduleInfo.startTime"
                            ref="flatP"
                            :disabled="scheduleInfo.type == 'C' ? true : false"
                            class="form-control"
                            :config="flatPickrTimeConfig"
                            style="font-size: 11px; padding: 0px !important; text-align: center"
                            :placeholder="$t('label.L0077')"
                          />
                        </b-input-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="1" style="margin: 5% 10% 0 10%"> ~ </b-col>
                  <!-- <b-col md="4" style="padding: 0px 0px 0px 7px !important"> -->
                  <b-col md="4" style="padding: 0px !important">
                    <b-form-group style="margin-bottom: 0px; width: 80px">
                      <validation-provider
                        :rules="scheduleInfo.type == 'C' ? '' : 'required'"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            <feather-icon icon="CalendarIcon" />
                          </b-input-group-prepend>
                          <flat-pickr
                            v-model="scheduleInfo.endTime"
                            :disabled="scheduleInfo.type == 'C' ? true : false"
                            class="form-control"
                            :config="flatPickrTimeConfig"
                            style="font-size: 12px; padding: 0px !important; text-align: center"
                            :placeholder="$t('label.L0078')"
                          />
                        </b-input-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- <label
                    for=""
                    style="
                      padding-left: 8px;
                      padding-right: 8px;
                      padding-top: 7px;
                      text-align: center;
                    "
                  >
                    ~
                  </label> -->

                  <small class="text-danger" style="margin-top: -0.8rem">
                    {{ errMsg }}
                  </small>
                </b-row>
              </b-col>
              <b-col md="2"> </b-col>
            </b-row>
          </fieldset>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import { Korean } from "flatpickr/dist/l10n/ko.js";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import vSelect from "vue-select";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BButtonGroup,
  BInputGroupPrepend,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import dayjs from "dayjs";

export default {
  name: "equipPopup",
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormValidFeedback,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButtonGroup,
    flatPickr,
    BInputGroupPrepend,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    propTitle: String,
    propType: String,
    tunnelCodePopup: Array,
    tunnelInfoList: Array,
  },
  data() {
    return {
      startBool: null,
      endBool: null,
      mergeDate: "",
      dateSeparator: "",
      positionList: [],
      roadConfig: null,
      linkCode: "",
      flatPickrTimeConfig: {
        locale: this.$i18n.locale == "ko" ? Korean : null,
        enableTime: true,
        noCalendar: true,
        // dateFormat: "H:i",
        // defaultDate: new dayjs().format("HH:mm") ,
        time_24hr: true,
        allowInput: false,
        // defaultDate: new dayjs().format("HH:mm") ,
        plugins: [
          new ConfirmDatePlugin({
            confirmIcon: "",
            confirmText: "OK",
            showAlways: true,
            theme: "light", // or "dark"
          }),
        ],
      },
      // flatPickrConfig: {
      //   mode:'range',
      //   locale: Korean,
      //   enableTime: true,
      //   // enableTime: false,
      //   allowInput: true,
      //   // time_24hr: true,
      //   dateFormat: "Y-m-d H:i",
      //   defaultHour: [5, 10],
      //   // defaultDate: new dayjs().format("YYYY-MM-DD HH:mm") ,
      //   defaultDate: new dayjs().format("YYYY-MM-DD HH:mm") ,
      //   plugins: [
      //     new ConfirmDatePlugin({
      //       confirmIcon: "",
      //       confirmText: "OK",
      //       showAlways: true,
      //       theme: "light", // or "dark"
      //     }),
      //   ],
      // },
      flatPickrConfig: {
        mode: "range",
        locale: this.$i18n.locale == "ko" ? Korean : null,
        enableTime: false,
        // enableTime: false,
        // minDate: "today",
        // allowInput: true,
        allowInput: false,
        // time_24hr: true,
        dateFormat: "Y-m-d",
        defaultHour: [5, 10],
        // defaultDate: new dayjs().format("YYYY-MM-DD HH:mm") ,
        defaultDate: new dayjs().format("YYYY-MM-DD"),
        plugins: [
          new ConfirmDatePlugin({
            confirmIcon: "",
            confirmText: "OK",
            showAlways: true,
            theme: "light", // or "dark"
          }),
        ],
      },
      selectTunnelInfo: null,
      scheduleInfo: {
        linkCode: "",
        tunnelCode: "",
        bound: "0",
        startPosition: null,
        endPosition: null,
        startDate: "",
        endDate: "",
        startTime: null,
        endTime: null,
        regId: "",
        type: "R",
        scheduleName: "",
        rstartPosition: null,
        rendPosition: null,
      },
      optionsBound: [
        {
          text: this.$t("label.L0020"),
          data: "0",
        },
        { text: this.$t("label.L0019"), data: "1" },
      ],
      startPosition: 0,
      endPosition: 0,
      createTunnelInfoList: [],
    };
  },
  methods: {
    async onBtnOk(data) {
      data.preventDefault();

      if (
        this.scheduleInfo.tunnelCode == "DBS" &&
        this.scheduleInfo.bound == "1"
      ) {
        this.$swal({
          heightAuto: false,
          title: "도봉지하차도는 상행을\n지정할 수 없습니다.",
          icon: "info",
          buttons: true,
          showCancelButton: false,
          confirmButtonText: this.$t("button.B0001"),
          cancelButtonText: this.$t("button.B0002"),
          reverseButtons: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn btn-primary ml-1",
            cancelButton: "btn btn-outline-danger",
          },
          buttonsStyling: false,
        });
        return "";
      }

      this.scheduleInfo.startTime == null
        ? (this.scheduleInfo.startTime = "")
        : "";
      this.scheduleInfo.endTime == null ? (this.scheduleInfo.endTime = "") : "";
      // 종료시간과 시작시간계산
      if (!this.validationTime) {
        this.$swal({
          heightAuto: false,
          title: this.$t("message.M0018"), //"$t('message.M0018')",
          icon: "info",
          buttons: true,
          showCancelButton: false,
          confirmButtonText: this.$t("button.B0001"),
          cancelButtonText: this.$t("button.B0002"),
          reverseButtons: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn btn-primary ml-1",
            cancelButton: "btn btn-outline-danger",
          },
          buttonsStyling: false,
        });
        return "";
      }

      const success = await this.$refs.simpleRules.validate();

      const TunnelPositionCheck = this.tunnelPositionAlert();
      if (!TunnelPositionCheck) {
        this.$swal({
          heightAuto: false,
          title:
            // "$t('message.M0019')" +
            this.$t("message.M0019") +
            this.selectTunnelInfo.startPosition * 1000 +
            "m ~ " +
            this.selectTunnelInfo.endPosition * 1000 +
            "m ",
          // title: "시작이정과 종료이정 <br/> 범위를 확인해주세요. <br/> <small> 시작이정 : "+ this.selectTunnelInfo.startPosition + "</small> <br/> <small> 종료이정 : " + this.selectTunnelInfo.endPosition + "</small>",
          icon: "info",
          buttons: true,
          showCancelButton: false,
          confirmButtonText: this.$t("button.B0001"),
          cancelButtonText: this.$t("button.B0002"),
          reverseButtons: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn btn-primary ml-1",
            cancelButton: "btn btn-outline-danger",
          },
          buttonsStyling: false,
        });
        return "";
      }

      // if(this.validDate()){
      const resultPositionCheck = this.positionerrAlert();
      if (!resultPositionCheck) {
        this.$swal({
          heightAuto: false,
          // title: "$t('message.M0020')",
          title: this.$t("message.M0020"),
          icon: "info",
          buttons: true,
          showCancelButton: false,
          confirmButtonText: this.$t("button.B0001"),
          cancelButtonText: this.$t("button.B0002"),
          reverseButtons: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn btn-primary ml-1",
            cancelButton: "btn btn-outline-danger",
          },
          buttonsStyling: false,
        });
        return "";
      }

      if (success && this.validationTime) {
        // this.scheduleInfo.linkCode =
        //   this.linkCode + (this.scheduleInfo.bound == 0 ? "-MCT" : "-CMT");

        var toFixedStartP = Number(this.scheduleInfo.startPosition).toFixed(2);
        var toFixedEndP = Number(this.scheduleInfo.endPosition).toFixed(2);
        this.scheduleInfo.startPosition = toFixedStartP;
        this.scheduleInfo.endPosition = toFixedEndP;
        await this.setDate();
        if (this.propType == "update") {
          this.$emit("update-obj", this.scheduleInfo);
        } else {
          this.$emit("create-obj", this.scheduleInfo);
        }
        this.$refs["data"].hide();
      }
      // }
    },
    onBtnCancel() {},

    positionerrAlert() {
      if (
        Number(this.scheduleInfo.startPosition) >=
        Number(this.scheduleInfo.endPosition)
      ) {
        return false;
      }
      return true;
    },

    tunnelPositionAlert() {
      var tunnelInfoArr = this.tunnelInfoList;
      var findTunnelCode = false;
      let inputStartP = Number(this.scheduleInfo.startPosition);
      let inputEndP = Number(this.scheduleInfo.endPosition);
      let rstartPosition = Number(this.scheduleInfo.rstartPosition);
      let rendPosition = Number(this.scheduleInfo.rendPosition);

      //등록시 실행
      if (this.propType == "create") {
        var createTunnelInfoList = this.createTunnelInfoList;
        for (let obj of createTunnelInfoList) {
          if (this.scheduleInfo.tunnelCode == obj.tunnelCode) {
            rstartPosition = Number(obj.startPosition * 1000);
            rendPosition = Number(obj.endPosition * 1000);
          }
        }
      }

      for (let obj of tunnelInfoArr) {
        if (obj.tunnelCode == this.scheduleInfo.tunnelCode) {
          this.selectTunnelInfo = obj;
          findTunnelCode = true;
        }
      }

      if (findTunnelCode) {
        // if(inputStartP >= this.selectTunnelInfo.startPosition && inputEndP <= this.selectTunnelInfo.endPosition ){
        if (inputStartP >= rstartPosition && inputEndP <= rendPosition) {
          return true;
        }
      }

      return false;
    },

    // 수정 클릭시 실행
    async updatepopup(data) {
      if (data && data.item) {
        this.scheduleInfo = await { ...data.item };
        this.setUpdateDate();
        this.$refs.data.show();
      }
    },

    // 일정등록 클릭시 실행
    createpopup(createTunnelInfoList) {
      this.createTunnelInfoList = createTunnelInfoList;
      let defaultTunnelCode = this.tunnelCodePopup[0].data; // 모든 터널중 첫번째 터널로 설정
      this.initData(defaultTunnelCode);
      this.setCreateDate();
      this.$refs.data.show();
    },

    // 초기화
    initData(defaultTunnelCode) {
      this.scheduleInfo = {
        tunnelCode: defaultTunnelCode,
        bound: "0",
        startPosition: null,
        endPosition: null,
        regId: "",
        type: "R",
        startTime: null,
        endTime: null,
        scheduleName: "",
        rStartPosition: null,
        rEndPosition: null,
      };
    },

    setDate() {
      var date = this.mergeDate.split(this.dateSeparator);
      if (date.length == 1) {
        this.scheduleInfo.startDate = date[0];
        this.scheduleInfo.endDate = date[0];
      } else {
        this.scheduleInfo.startDate = date[0];
        this.scheduleInfo.endDate = date[1];
      }
    },

    setUpdateDate() {
      if (this.$i18n.locale == 'ko') {
        this.flatPickrConfig.locale = Korean
        this.flatPickrTimeConfig.locale = Korean
        this.dateSeparator = ' ~ '
      } else {
        this.flatPickrConfig.locale = null
        this.flatPickrTimeConfig.locale = null
        this.dateSeparator = ' to '
      }
      this.mergeDate = `${this.scheduleInfo.startDate}${this.dateSeparator}${this.scheduleInfo.endDate}`; // 기간
    },

    initPositionList() {
      var startPosition = this.startPosition * 10;
      var endPosition = this.endPosition * 10;
      var len = Math.abs(endPosition - startPosition);
      this.positionList = [];
      for (var i = 0; i <= len; i++) {
        var position = (Math.min(startPosition, endPosition) + i) / 10;
        if (Number.isInteger(position))
          this.positionList.push({
            text: position + ".0 m",
            data: position + ".0",
          });
        else
          this.positionList.push({
            text: position + " m",
            data: position + "",
          });
      }
    },

    // validDate(){
    //   let startDate = this.scheduleInfo.startDate;
    //   let endDate = this.scheduleInfo.endDate;
    //   let sDate = new Date(startDate);
    //   let eDate = new Date(endDate);
    //   if(eDate - sDate < 0){
    //     this.$swal({
    //       heightAuto: false,
    //       title: '종료 일자를 시작 일자 <br/>이전으로 지정할 수 없습니다.',
    //       icon: "error",
    //       confirmButtonText: "확인",
    //       allowEscapeKey: false,
    //       allowOutsideClick: false,
    //       customClass: {
    //         confirmButton: "btn btn-primary ml-1"
    //       },
    //       buttonsStyling: false,
    //     });
    //     this.scheduleInfo.endDate = startDate;
    //     return false;
    //   }else{
    //     return true;
    //   }
    // },

    setCreateDate() {
      var dateFrom = dayjs().format("YYYY-MM-DD");
      var dateTo = dayjs().add(1, "d").format("YYYY-MM-DD");
      this.mergeDate = `${dateFrom}${this.dateSeparator}${dateTo}`;
    },

    buttonFunction(d) {
      if (d == "R") {
        this.scheduleInfo.type = d;
      } else {
        this.scheduleInfo.type = d;
        this.scheduleInfo.startTime = null;
        this.scheduleInfo.endTime = null;
      }
    },
  },

  computed: {
    validationTime() {
      if (
        this.scheduleInfo.endTime == null ||
        this.scheduleInfo.startTime == null
      ) {
        return true;
      } else if (
        this.scheduleInfo.endTime != null &&
        this.scheduleInfo.endTime != "" &&
        this.scheduleInfo.startTime != null &&
        this.scheduleInfo.startTime != ""
      ) {
        // console.log(Number(this.scheduleInfo.endTime.replace(':',''))- Number(this.scheduleInfo.startTime.replace(':','')) <0 ? false :true)
        return Number(this.scheduleInfo.endTime.replace(":", "")) -
          Number(this.scheduleInfo.startTime.replace(":", "")) <
          0
          ? false
          : true;
      } else {
        return true;
      }
    },

    errMsg() {
      if (this.scheduleInfo.type == "C") {
        return "";
      } else {
        if (
          this.scheduleInfo.startTime == null ||
          this.scheduleInfo.endTime == null
        ) {
          return "";
        }
        this.startBool =
          this.scheduleInfo.startTime == null ||
          this.scheduleInfo.startTime == ""
            ? true
            : false;
        this.endBool =
          this.scheduleInfo.endTime == null || this.scheduleInfo.endTime == ""
            ? true
            : false;
        if (this.startBool && this.endBool) {
          // return "시작 시간과 종료 시간은 필수입니다.";
          return this.$t("message.M0062") ;
        } else if (this.startBool) {
          // return "시작 시간은 필수입니다.";
          return this.$t("message.M0063") ;
        } else if (this.endBool) {
          // return "종료 시간은 필수입니다.";
          return this.$t("message.M0064") ;
        }
      }

      return "";
    },
  },

  mounted() {
    // var roadConfig = this.$storage.data[this.$storage.data["local"]];
    // console.log("???config :: " , roadConfig);
    //   this.roadConfig = roadConfig;
    // this.linkCode = roadConfig[0].linkCode;
    // this.startPosition = roadConfig[0].startPosition;
    // this.endPosition = roadConfig[0].endPosition;
    // this.initPositionList();
  },

  created() {
    if (this.$i18n.locale == "ko") {
      this.dateSeparator = " ~ ";
    } else {
      this.dateSeparator = " to ";
    }
    // EventBus.$on('selectTunnelInfo', (val) => {
    //   this.selectTunnelInfo = val;
    // });
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.sectionFieldset {
  border: solid 1px rgba(0, 0, 0, 0.125);
  padding-top: 5px;
  padding-right: 12px;
  padding-bottom: 10px;
  padding-left: 12px;
}

.sectionFieldset legend {
  width: inherit;
  /* Or auto */
  padding: 0 10px;
  /* To give a bit of padding on the left and right */
  border-bottom: none;
  font-weight: bold;
  font-size: 12px;
}
.sectionFieldset .custom-control-inline {
  margin-right: 2rem;
}
label {
  padding-top: 6px;
  color: #5e5873;
  font-size: 14px;
}

.form-control.is-valid {
  border-color: #d8d6de !important;
  background-image: none !important;
  background-repeat: no-repeat;
  background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
}
.form-control.is-invalid {
  border-color: #ea5455 !important;
  background-repeat: no-repeat !important;
  background-image: none !important;
  background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem) !important;
}
.input-group.is-valid .input-group-text {
  border-color: #d8d6de !important;
}
.input-group.is-invalid .input-group-text {
  border-color: #ea5455 !important;
}
.input-group-text {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>
<style>
.bodyCustomSchedule {
  /* padding: 1.4rem 0.7rem 0.3rem 0.7rem; */
  padding: 0.5rem 0.7rem 0.3rem 0.7rem;

}
.footerCustomSchedule {
  border-top-width: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 17px !important;
}

.text-right {
  text-align: right;
}
.vue-select-right .vs__dropdown-toggle {
  right: 0;
}
.my-select .vs__selected-options {
  text-align: right !important;
}

.noCalendar {
  width: 100px;
}

.merge-custom-hja {
  margin-left: 0px !important;
}
</style>
