<template>
  <div>
    <b-card class="col-12 d-flex flex-column" no-body style="min-height: 85vh">
      <b-list-group class="my-2">
        <b-list-group-item>
          <b-row align-v="center">
            <b-col md="6">
              <b-row align-v="center">
                <b-col md="2" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      width: 100px;
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0017") }}</label
                  >
                </b-col>
                <b-col md="5">
                  <v-select
                    v-model="tunnelCodeStatus"
                    :clearable="false"
                    label="text"
                    :reduce="(d) => d.data"
                    :options="sortedItems(tunnelCodeSearch)"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0087") }}</label
                  >
                </b-col>
                <b-col md="9">
                  <b-button-group>
                    <b-button
                      variant="outline-primary"
                      @click="searchBound = 'all'"
                      :pressed="searchBound == 'all' ? true : false"
                      >{{ $t("label.L0021") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      @click="searchBound = '0'"
                      :pressed="searchBound == '0' ? true : false"
                      >{{ $t("label.L0020") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      @click="searchBound = '1'"
                      :pressed="searchBound == '1' ? true : false"
                      >{{ $t("label.L0019") }}</b-button
                    >
                  </b-button-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row align-v="center">
            <b-col md="6">
              <b-row align-v="center">
                <b-col md="2" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0047") }}</label
                  >
                </b-col>
                <b-col md="5">
                  <b-button-group>
                    <b-button
                      variant="outline-primary"
                      @click="changeSearchDateType('all')"
                      :pressed="searchDateType == 'all' ? true : false"
                      >{{ $t("label.L0021") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      @click="changeSearchDateType('startDate')"
                      :pressed="searchDateType == 'startDate' ? true : false"
                      >{{ $t("label.L0075") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      @click="changeSearchDateType('endDate')"
                      :pressed="searchDateType == 'endDate' ? true : false"
                      >{{ $t("label.L0076") }}</b-button
                    >
                  </b-button-group>
                </b-col>
                <b-col md="5">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="CalendarIcon" />
                      </b-input-group-prepend>
                      <flat-pickr
                        v-model="selectedDate"
                        ref="dateFrom"
                        class="form-control"
                        :placeholder="this.flatpickrPlaceholder"
                        :config="flatPickrConfig"
                      />
                    </b-input-group>
                  </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0054") }}</label
                  >
                </b-col>
                <b-col md="9" style="padding-right: 0;">
                  <!--  <b-button-group>
                    <b-button
                      v-model="searchInputType"
                      @click="searchInputType = 'position'"
                      variant="outline-primary"
                      :pressed="searchInputType == 'position' ? true : false"
                      >{{ $t("label.L0052") }}</b-button
                    >
                    <b-button
                      v-model="searchInputType"
                      @click="searchInputType = 'scheduleName'"
                      variant="outline-primary"
                      :pressed="
                        searchInputType == 'scheduleName' ? true : false
                      "
                      >{{ $t("label.L0072") }}</b-button
                    >
                  </b-button-group> -->
                  <b-row align-v="center">
                    <b-col style="padding-left: 0px;">
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="SearchIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="searchString"
                          :placeholder="$t('message.M0005')"
                          v-model="searchString"
                          type="search"
                          @keyup.enter="doFilter"
                        />
                        <b-input-group-append>
                          <b-button variant="primary" @click="doFilter">
                            {{ $t("button.B0008") }}
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-col> 
              </b-row>
            </b-col>
            <b-col md="2" class="text-right" >
              <b-row align-v="center"  >
                <b-col >
                  <b-button variant="primary" @click="createRow()" style="">{{
                    $t("label.L0172")
                  }}</b-button>
                </b-col>
              </b-row>
            </b-col>
            <!-- <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0047") }}</label
                  >
                </b-col>
                
              </b-row>
            </b-col> -->
          </b-row>

        </b-list-group-item>

        <!-- <b-list-group-item>
          <b-row align-v="center">
            <b-col md="4">
              <b-row align-v="center">
              </b-row>
            </b-col >
            <b-col md="8">
              <b-row>
                <b-col md="2">
                  <b-col align="right">
                    <b-button variant="primary" @click="createRow()" style="">{{
                      $t("label.L0172")
                    }}</b-button>
                  </b-col>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row align-v="center">
                <b-col md="8" style="padding-left: 0px; padding-right: 23px">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="searchString"
                      :placeholder="$t('message.M0005')"
                      v-model="searchString"
                      type="search"
                      @keyup.enter="doFilter"
                    />
                    <b-input-group-append>
                      <b-button variant="primary" @click="doFilter">
                        {{ $t("button.B0008") }}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="2">
              <b-col align="right">
                <b-button variant="primary" @click="createRow()" style="">{{
                  $t("label.L0172")
                }}</b-button>
              </b-col>
            </b-col> 
          </b-row>
        </b-list-group-item> -->
      </b-list-group>

      
      <b-row class="padding-base-12 flex-grow-1">
        <b-table
          sticky-header
          ref="maintable"
          striped
          hover
          responsive
          :items="getList"
          :fields="tableColumns"
          :per-page="rowNum"
          :sort-by.sync="sortValue"
          :sort-desc.sync="sortDesc"
          :current-page="currentPage"
          show-empty
          :empty-text="$t('message.M0009')"
          :empty-filtered-text="$t('message.M0009')"
          style="max-height: 100%"
          class="mb-0 text-center"
        >
          <!-- 번호 no -->
          <template #cell(idx)="data">
            {{ rowNum * (currentPage - 1) + (data.index + 1) }}
          </template>

          <!-- 방면 -->
          <template #cell(bound)="data">
            <b-badge pill variant="light-warning" v-if="data.item.bound == 1">
              {{ $t("label.L0019") }}
              <!-- {{ data.item.direction }} -->
            </b-badge>
            <b-badge pill variant="light-primary" v-else>
              {{ $t("label.L0020") }}
              <!-- {{ data.item.direction }} -->
            </b-badge>
          </template>
          <template #cell(startDate)="data">
            {{ data.item.startDate }}
          </template>

          <!-- 터널명 -->
          <template #cell(location)="data">
            <b-badge pill variant="light-success">
              {{ data.item.location }}
            </b-badge>
          </template>

          <template #cell(startPosition)="data">
            <div style="text-align: right">{{ data.item.startPosition }} m</div>
          </template>

          <template #cell(endPosition)="data">
            <div style="text-align: right">{{ data.item.endPosition }} m</div>
          </template>

          <!-- 작업버튼 -->
          <template #cell(work)="data">
            <b-button-group size="sm">
              <b-button variant="outline-primary" @click="updateRow(data)">{{
                $t("button.B0006")
              }}</b-button>
              <b-button variant="outline-primary" @click="deleteRow(data)">{{
                $t("button.B0007")
              }}</b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-row>
      <b-row class="my-2" align-v="center" style="bottom: 0; width: 100%">
        <b-col md="1" class="ml-1">
          <b-form-group class="mb-0">
            <v-select
              id="perPageSelect"
              v-model="rowNum"
              size="sm"
              :options="perPageOptions"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col>
          {{ $t("label.L0021") }} {{ dataMeta.of }} {{ $t("label.L0111") }}
          {{ dataMeta.from }} ~ {{ dataMeta.to }} {{ $t("label.L0112") }}
        </b-col>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            first-number
            last-number
            :per-page="rowNum"
            align="right"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <schedule-modal
      ref="update"
      :propTitle="propTitle"
      :propType="propType"
      :tunnelCodePopup="tunnelCodePopup"
      :tunnelInfoList="tunnelInfoList"
      @update-obj="updateRowFunction"
    />
    <schedule-modal
      ref="create"
      :propTitle="propTitle"
      :propType="propType"
      :tunnelCodePopup="tunnelCodePopup"
      :tunnelInfoList="tunnelInfoList"
      @create-obj="createBtnFunction"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import scheduleModal from "./components/schedulePopup.vue";
import scheduleInterface from "./scheduleInterface";
import dayjs from "dayjs";
import flatPickr from "vue-flatpickr-component";
import { English } from "flatpickr/dist/l10n/default.js";
import { Korean } from "flatpickr/dist/l10n/ko.js";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import {
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BButton,
  BPagination,
  BTable,
  BButtonGroup,
  BCard,
  BDropdown,
  BDropdownItem,
  BBadge,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
// import detailPopup from "./viewDetail.vue";
export default {
  name: "schedule",
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BListGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    BPagination,
    BTable,
    BButtonGroup,
    BCard,
    vSelect,
    scheduleModal,
    BDropdown,
    BDropdownItem,
    BBadge,
    BListGroupItem,
    // detailPopup,
    flatPickr,
  },
  data() {
    return {
      flatpickrPlaceholder: this.$t("message.M0010"), // 달력 placeholder text
      sortDesc: false,
      sortValue: "startDate",
      boundNameString: {
        all: () => this.$t("schedule.search.searchBound.bound"),
        0: () => this.$t("schedule.search.searchBound.beginBound"),
        1: () => this.$t("schedule.search.searchBound.endBound"),
      },
      propTitle: "",
      // mergeDate: '', // 선택 일자
      selectedDate: "", // 선택 일자
      propType: "",
      searchOption: this.$t("label.L0115"),
      searchOne: "",
      searchTwo: "",
      tableColumns: [
        {
          key: "idx",
          label: this.$t("label.L0055"),
          thStyle: { width: "5%", "vertical-align": "middle" },
        },
        {
          key: "location",
          label: this.$t("label.L0017"),
          thStyle: { width: "9%", "vertical-align": "middle" },
        },
        {
          key: "scheduleName",
          label: this.$t("label.L0072"),
          thStyle: { width: "15%", "vertical-align": "middle" },
        },
        {
          key: "bound",
          label: this.$t("label.L0087"),
          formatter: this.columFomatter,
          thStyle: { width: "7%", "vertical-align": "middle" },
        },
        {
          key: "startPosition",
          label: this.$t("label.L0073"),
          thStyle: { width: "10%", "vertical-align": "middle" },
          formatter: (value, key, item) => {
            // return Number(item.startPosition).toFixed(2) * 1000 +'m';
            return item.startPosition + "m";
          },
        },
        {
          key: "endPosition",
          label: this.$t("label.L0074"),
          thStyle: { width: "10%", "vertical-align": "middle" },
          formatter: (value, key, item) => {
            // return Number(item.endPosition).toFixed(2) * 1000 +'m';
            return item.endPosition + "m";
          },
        },
        {
          key: "startDate",
          label: this.$t("label.L0075"),
          thStyle: { width: "10%", "vertical-align": "middle" },
          formatter: (value, key, item) => {
            return Number(item.startDate.replaceAll("-", ""));
            // return item.startDate+'('+ this.weekStr[item.startDay]()+')';
          },
        },
        {
          key: "endDate",
          label: this.$t("label.L0076"),
          thStyle: { width: "10%", "vertical-align": "middle" },
          formatter: (value, key, item) => {
            return item.endDate;
            // return item.endDate+'('+ this.weekStr[item.endDay]()+')';
          },
        },
        //     { key: "type", label: '일정 유형' , thStyle: { width: '10%' },formatter: (value, key, item) => {
        //   if(item.type=='R') return "반복";
        //   if(item.type=='C') return "연속";
        // }},
        {
          key: "startTime",
          label: this.$t("label.L0077"),
          thStyle: { width: "7%", "vertical-align": "middle" },
          formatter: (value, key, item) => {
            if (item.type == "C") {
              return "-";
            } else {
              return item.startTime;
            }
          },
        },
        {
          key: "endTime",
          label: this.$t("label.L0078"),
          thStyle: { width: "7%", "vertical-align": "middle" },
          formatter: (value, key, item) => {
            if (item.type == "C") {
              return "-";
            } else {
              return item.endTime;
            }
          },
        },
        // { key: "regId", label: '작성자' , thStyle: { width: '10%' }},
        {
          key: "work",
          label: this.$t("label.L0064"),
          thStyle: { width: "16%" },
        },
      ],
      weekStr: {
        0: () => this.$t("label.L0116"),
        1: () => this.$t("label.L0117"),
        2: () => this.$t("label.L0118"),
        3: () => this.$t("label.L0119"),
        4: () => this.$t("label.L0120"),
        5: () => this.$t("label.L0121"),
        6: () => this.$t("label.L0122"),
      },
      // flatPickrConfig: {
      //   locale: Korean,
      //   enableTime: false,
      //   dateFormat: "Y-m-d",
      //   allowInput: true,
      //   //defaultDate: new dayjs().format("YYYY-MM-DD"),
      //   allowInput: false, // 사용자 직접 입력 막기
      //   //maxDate: new Date(),
      //   plugins: [
      //     // https://chmln.github.io/flatpickr/plugins/
      //     new ConfirmDatePlugin({
      //       // confirmIcon: '<feather-icon icon="CalendarIcon"/>', // your icon's html, if you wish to override
      //       confirmText: "OK",
      //       confirmIcon: "",
      //       showAlways: true,
      //       theme: "light", // or "dark"
      //     }),
      //   ],
      // },
      flatPickrConfig: {
        // mode: "range", // 범위 선택
        locale: this.$i18n.locale == "ko" ? Korean : null,
        enableTime: false,
        dateFormat: "Y-m-d",
        defaultDate: "",
        allowInput: false,
        allowInput: false, // 사용자 직접 입력 막기
        clickOpens: false, // 달력 클릭 가능 여부
        // defaultDate: new dayjs().format("YYYY-MM-DD"),
        // maxDate: new Date(),
        // defaultDate: new dayjs().format("YYYY-MM-DD"),
        plugins: [
          // https://chmln.github.io/flatpickr/ plugins/
          new ConfirmDatePlugin({
            // confirmIcon: '<feather-icon icon="CalendarIcon"/>', // your icon's html, if you wish to override
            confirmText: "OK",
            confirmIcon: "",
            showAlways: true,
            theme: "light", // or "dark"
          }),
        ],
      },
    };
  },
  setup() {
    const {
      refetchData,
      getList,
      tableColumns,
      rowNum,
      totalRows,
      currentPage,
      dataMeta,
      perPageOptions,
      filter,
      maintable,
      deleteFunction,
      createFunction,
      updateFunction,
      searchDateType,
      searchBound,
      // searchStartDate,
      // searchEndDate,
      searchDate,
      searchInputType,
      searchPosition,
      searchString,
      tunnelCodeSearch,
      tunnelCodeStatus,
      tunnelCodePopup,
      tunnelInfoList,
    } = scheduleInterface();

    return {
      refetchData,
      getList,
      tableColumns,
      rowNum,
      totalRows,
      currentPage,
      dataMeta,
      perPageOptions,
      filter,
      maintable,
      deleteFunction,
      createFunction,
      updateFunction,
      searchDateType,
      searchBound,
      // searchStartDate,
      // searchEndDate,
      searchDate,
      searchInputType,
      searchPosition,
      searchString,
      tunnelCodeSearch,
      tunnelCodeStatus,
      tunnelCodePopup,
      tunnelInfoList,
    };
  },
  methods: {
    sortedItems(items) {
      return items.slice().sort((a, b) => {
        if (a.text == "All") return -1;
        if (b.text == "All") return 1;
        // 이름을 기준으로 오름차순 정렬
        return a.text.localeCompare(b.text); // 음수: a가 b보다 앞, 0: a와 b가 동일, 양수: a가 b보다 뒤
      });
    },
    changeSearchDateType(type) {
      // 검색 기간 타입 변경시 호출
      this.searchDateType = type;
      if (type == "all") {
        this.selectedDate = "";
        this.flatPickrConfig.clickOpens = false;
        this.flatpickrPlaceholder = this.$t("message.M0010");
      } else {
        this.flatPickrConfig.clickOpens = true;
        this.flatpickrPlaceholder = this.$t("message.M0007");
      }

    },
    columFomatter(data) {
      return data == "0" ? this.$t("label.L0019") : this.$t("label.L0020");
    },
    async doFilter() {
      this.currentPage = 1;
      // this.searchStartDate = this.mergeDate.split(" ~ ")[0];
      // this.searchEndDate = this.mergeDate.split(" ~ ")[1];
      this.searchDate = this.selectedDate; // 검색일자를 선택일자로 설정

      if (this.searchInputType == "position" && this.searchString != "") {
        this.searchPosition = (Number(this.searchString) / 1000).toFixed(2);
      }
      await this.refetchData();
    },
    createRow() {
      // 일정 추가 버튼 클릭시 팝업창 타이틀, 속성  props추가 및 팝업컴포넌트 내 메소드 호출
      this.propTitle = this.$t("label.L0088");
      this.propType = "create";
      // this.$refs.create.createpopup();
      this.$refs.create.createpopup(this.tunnelInfoList);
    },
    updateRow(data) {
      // 수정 버튼 클릭시 팝업창 타이틀, 속성  props추가 및 팝업컴포넌트 내 메소드 호출
      this.propTitle = this.$t("label.L0123");
      this.propType = "update";
      this.$refs.create.updatepopup(this.getTunnelInfoList);
      this.$refs.update.updatepopup(data);
    },
    async createBtnFunction(data) {
      // 팝업창내 저장버튼 클릭시 값 가져오고 scheduleInterface 로 전송 및 테이블 리프레쉬
      await this.createFunction(data);
      this.filter = "";
      this.selectedDate = ""; // 선택 일자
      this.searchDate = this.selectedDate; // 검색일자를 오늘 일자로 설정
      await this.refetchData();
    },
    async updateRowFunction(data) {
      // 팝업창내 저장버튼 클릭시 값 가져오고 scheduleInterface 로 전송 및 테이블 리프레쉬
      this.selectedDate = ""; // 선택 일자
      this.searchDate = this.selectedDate; // 검색일자를 오늘 일자로 설정
      await this.updateFunction(data);
      await this.refetchData();
    },
    deleteRow(data) {
      this.$swal({
        heightAuto: false,
        title: this.$t("message.M0006"),
        icon: "info",
        buttons: true,
        showCancelButton: true,
        confirmButtonText: this.$t("button.B0001"),
        cancelButtonText: this.$t("button.B0002"),
        reverseButtons: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const response = await this.deleteFunction(data.item.no);
          if (response.data.success) {
            this.selectedDate = ""; // 선택 일자
            this.searchDate = this.selectedDate; // 검색일자를 오늘 일자로 설정
            // true 일때 리프레쉬
            await this.refetchData();
          }
        }
      });
    },
    setUpdateDate() {

      if (this.$i18n.locale == 'ko') {
        this.flatPickrConfig.locale = Korean
      } else {
        this.flatPickrConfig.locale = null
      }


      if (this.searchDateType == "all") {
        this.flatpickrPlaceholder = this.$t("message.M0010");
      } else {
        this.flatpickrPlaceholder = this.$t("message.M0007");
      }
    },
  },
  computed: {},

  mounted() {
  },

  async created() {
    // var dateTo = dayjs().format("YYYY-MM-DD");
    // // this.searchStartDate = dateTo;
    // var dateFrom = dayjs().startOf('day').format("YYYY-MM-DD");
    // var dateTo = dayjs().format("YYYY-MM-DD");
    // this.mergeDate = `${dateFrom} ~ ${dateTo}`; // 기간
    // this.searchStartDate = dateFrom;
    // this.searchEndDate = dateTo;
    // this.selectedDate = dayjs().startOf('day').format("YYYY-MM-DD"); // 오늘 일자
    // this.searchDate = this.selectedDate; // 검색일자를 오늘 일자로 설정
    this.searchString = ""; // 검색할 텍스트
    this.selectedDate = ""; // 선택 일자
    this.searchDate = this.selectedDate; // 선택 일자 빈값으로 설정
    await this.refetchData();
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      this.setUpdateDate();
      this.doFilter();
      
      for (var i = 0; i < this.tableColumns.length; i++) {
        var scheduleTableColumns = this.tableColumns[i];
        if(i==0){
          scheduleTableColumns.label = this.$t("label.L0055");
        }
        if(i==1){
          scheduleTableColumns.label = this.$t("label.L0017");
        }
        if(i==2){
          scheduleTableColumns.label = this.$t("label.L0072");
        }
        if(i==3){
          scheduleTableColumns.label = this.$t("label.L0087");
        }
        if(i==4){
          scheduleTableColumns.label = this.$t("label.L0073");
        }
        if(i==5){
          scheduleTableColumns.label = this.$t("label.L0074");
        }
        if(i==6){
          scheduleTableColumns.label = this.$t("label.L0075");
        }
        if(i==7){
          scheduleTableColumns.label = this.$t("label.L0076");
        }
        if(i==8){
          scheduleTableColumns.label = this.$t("label.L0077");
        }
        if(i==9){
          scheduleTableColumns.label = this.$t("label.L0078");
        }
        if(i==10){
          scheduleTableColumns.label = this.$t("label.L0064");
        }
      }
    }
  },
};
</script>
<style>
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>
<style scoped>
.form-control {
  background-color: #ffffff;
}
.table-b-table-default th,
.table-b-table-default td {
  vertical-align: middle !important; /* 세로 가운데 정렬 */
  text-align: center !important; /* 가로 가운데 정렬 */
  white-space: normal !important;
}

/* 특수한 헤더 스타일을 적용하는 경우 */
.table-b-table-default th {
  background-color: #f8f9fa !important; /* 헤더 배경색 설정 */
  font-weight: bold !important; /* 헤더 글꼴 굵게 */
  white-space: normal;
  overflow-wrap: break-word;
  word-break: normal;
}
</style>
